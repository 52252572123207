<template>
  <b-row>

    <!-- Small -->
     

    <!-- Large -->
    <b-col cols="12">
      <b-form-group>
        <v-select  v-if="categories!=null"
          v-model="selectedCategories"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :options="categories" 
          
          class="select-size-lg"
          multiple
        >
         <template #option="{ title ,description }">
             
             
              <span> {{ title }}</span><br/>
                    <small class="text-muted"> {{ description }} </small>
            </template>
        </v-select>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store' 
import axios from '@axios'
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  created () {
    this.fetchCategories();
    this.selectedItems=this.initialCategories;
  
  },
  props :{
    initialCategories:[]
  },


  methods:{
     updateSelections(a) {
    
    console.log(a)
  },
    fetchCategories( ) {
      console.log("fetchCategories fetch");
      return new Promise((resolve, reject) => {
        axios
          .get('admin/cms/blogcategories' )
          .then((response)=> { 
           
         
           var data=  response.data;
           var responseData=JSON.parse(  JSON.stringify( data));
           
              this.categories=responseData.data;
            }
            )
          .catch(error => reject(error))
      })
    },
  },
  watch: {
    initialCategories: function (newVal,coldVal) {
   
      console.log("initial category updated");
      console.log(newVal);
      if(newVal==null||newVal==undefined)
     {
      this.selectedItems=[];
     }else

     {
      this.selectedItems=newVal;
     }
    
  }
},
computed:{
  selectedCategories:{
    get()
    {
      return this.selectedItems;

    },
    set(newValue)
    {
        this.selectedItems=newValue;
        this.$emit("selectionChanged", newValue);
    }

  }
},
  data() {
    return {
      categories:null,
      selectedItems:[]
     }
  }
   

}
</script>